import './App.css';
import moment from 'moment'
import React from 'react';
import ReactDataGrid from 'react-data-grid';
import Header from './Header';

class App extends React.Component {

  constructor(props) {
    super(props);

    // ReactDataGrid is tricky in the sense that uses the rowFetcher to fetch its state
    // so we always need to make sure there is some array with the correct UI state ready
    // in our case, this is this.state.visiblePlayers
    // according to this https://github.com/adazzle/react-data-grid/issues/1722 v7 should
    // now use the rows parameter inside the grid which makes it more React like but I had
    // issues getting that to work...
    // it's still a mystery though how it knows that it needs to rerender its grid...
    this.state = {
      allPlayers: [],
      visiblePlayers: []
    };

    this.filter = null;
    this.sorting = null;
  }

  componentDidMount() {
    this.fetchPlayers();
  }

  async fetchPlayers() {
    // fetching has to happen here else the React Data Grid is f***
    // for some reason if it enters via props, it cannot rerender its grid
    // might the same issue as https://github.com/adazzle/react-data-grid/issues/1722
    // as rowsCount is the only thing using a state variable to rerender the view
    var response = await fetch(`/api/results/players`);
    var data = await response.json();

    var allPlayers = data.players;

    this.sortByBib(allPlayers);  // we cannot sort on rendering because this.state.visiblePlayers should contain the UI state.

    this.setState({
      allPlayers: allPlayers,
      visiblePlayers: allPlayers
    });

    // initial sorting:
    this.sorting = {column: 'rank', direction: 'ASC'};
    this.filterAndSortPlayers(allPlayers);

    this.refs.grid.onToggleFilter(); // else no filtering text fields are visible
  }

  sortByBib(players) {
    players.sort((p1, p2) => {
      return parseInt(p1.bib) - parseInt(p2.bib);
    });
  }

  toTimestamp(time) {
    if(!time) return '00:00:00';
    return moment(time).utc().format('HH:mm:ss');
  }

  rowGetter(index) {
    var player = this.state.visiblePlayers[index];

    var cleanedPlayer = {
      bib      : player.bib,
      name     : player.name,
      gameName : player.gameName,
      rank     : player.ranks.total,
      category : player.category,
      subRank  : player.subRanks.total,
      swim     : this.toTimestamp(player.results.swim),
      t1       : this.toTimestamp(player.results.t1),
      bike     : this.toTimestamp(player.results.bike),
      t2       : this.toTimestamp(player.results.t2),
      run      : this.toTimestamp(player.results.run),
      total    : this.toTimestamp(player.results.total),
      comment  : player.comment?player.comment.toUpperCase():null
    };

    return cleanedPlayer;
  }

  onGridSort(column, direction) {
    // keep track of sorting parameters in this.sorting:
    this.sorting = {column: column, direction: direction};
    if(direction == 'NONE') this.sorting = null;
    this.filterAndSortPlayers();
  }

  onAddFilter (filter) {
    // keep track of filtering parameters in this.filter:
    this.filter = filter;
    if(filter.filterTerm == '') this.filter = null;
    this.filterAndSortPlayers();
  };

  filterAndSortPlayers(_allPlayers) {
    var allPlayers = _allPlayers || this.state.allPlayers;

    // start from a copy of all players:
    var players = [...allPlayers];

    players = this.filterPlayers(players);
    this.applySpecialComments(players);
    this.sortPlayers(players);

    this.setState({
      visiblePlayers: players
    });
  }


  filterPlayers(players) {
    if(!this.filter) return players;

    switch(this.filter.column.key) {
      // partial match:
      case 'name':
      // players = players.filter(player => player.name.toLowerCase().includes(this.filter.filterTerm.toLowerCase()));
      players = players.filter(player => player.name.match(new RegExp(this.filter.filterTerm, 'i')));
      break;

      // exact match;
      case 'bib':
      players = players.filter(player => player.bib == this.filter.filterTerm);
      break;
    }

    return players;
  }

  sortPlayers(players) {
    // always sort by bib (even if we're not sorting):
    this.sortByBib(players);

    if(this.sorting) {
      players.sort((p1, p2) => {
        var order = 0;

        switch(this.sorting.column) {
          case 'bib':
          order = parseInt(p1.bib) - parseInt(p2.bib);
          break;

          case 'category':
          if(p1.category == p2.category) {
            // make sure it is sorted by subRank within the category:
            order = p1.subRanks.total - p2.subRanks.total;
          }else{
            order = p1.category > p2.category ? 1 : -1;
          }
          break;

          case 'swim':
          order = p1.results.swim - p2.results.swim;
          break;

          case 't1':
          order = p1.results.t1 - p2.results.t1;
          break;

          case 'bike':
          order = p1.results.bike - p2.results.bike;
          break;

          case 't2':
          order = p1.results.t2 - p2.results.t2;
          break;

          case 'run':
          order = p1.results.run - p2.results.run;
          break;

          case 'total':
          order = p1.results.total - p2.results.total;
          break;

          case 'rank':
          order = p1.ranks.total - p2.ranks.total;
          break;

          case 'subRank':
          order = p1.subRanks.total - p2.subRanks.total;
          break;

          default:
          order = p1[this.sorting.column] > p2[this.sorting.column] ? 1 : -1;
          break;
        }

        if(this.sorting.direction == 'DESC') order *= -1;

        return order;
      });
    }

    if(this.sorting && (this.sorting.column == 'name')) return; // we're done here

    if(this.sorting && (this.sorting.column == 'comment')) {
      // in case we are sorting by comment, then we will have sorted them alfabetically,
      // but in fact, we want the players with comments to rize above the players without comments:
      players.sort((p1, p2) => {
        var order = 0;
        if(p1.comment && !p2.comment) order = -1;
        if(!p1.comment && p2.comment) order = 1;
        return order;
      });



      return; // no more post-sorting her
    }

    // some special post-sorting:
    // (unless we are sorting by name or comment,
    // then there's not post-sorting, see 'if' above)
    // ================================


    // if the player has a comment, then we 'sort' those players to the bottom:
    players.sort((p1, p2) => {
      if(p1.comment && !p2.comment) return 1; // p1 is 'bigger' than p2
      if(!p1.comment && p2.comment) return -1;  // p1 is 'smaller' than p2
      return 0;  // p1 and p2 are the same
    });

    // next, sort by 'has total time' or not. Players without a total time are sorted to the bottom:
    players.sort((p1, p2) => {
      if(p1.results.total != null && p2.results.total == null) return -1; // p1 is 'smaller' than p2
      if(p1.results.total == null && p2.results.total != null) return 1;  // p1 is 'bigger' than p2
      return 0;  // p1 and p2 are the same
    });

    // after that, always sort by game:
    // a bit weird yes, but else both games mixed would mess up the view as ranking is calculated per game
    players.sort((p1, p2) => {
      return p1.gameId - p2.gameId;
    });
  }

  applySpecialComments(players) {
    for(var player of players) {
      if(player.results.total == null) player.comment = 'DNF';

      var isDNS = true;
      for(var key in player.results) {
        if(player.results[key] != null) {
          isDNS = false;
          break;
        }
      }
      if(isDNS) player.comment = 'DNS';
    }
    return players;
  }

  getYear() {
    // use the startTime of the first player as the year of the race:
    if(this.state.allPlayers.length) {
      var firstPlayer = this.state.allPlayers[0];
      var startDate = new Date(firstPlayer.startTime);
      return startDate.getFullYear();
    }else{
      return '';
    }
  }

  render() {
    var year = this.getYear();
    var title = `Resultaten ${year}`;
    document.title = title;

    return (
      <div>
        <Header title={title} subtitle={''} />
        <div className="scoreboard">
          <ReactDataGrid
            ref="grid"
            columns={[
              { key: 'bib', name: 'Bib', width: 83, sortable: true, filterable: true },
              { key: 'gameName', name: 'Wedstrijd', width: 80 },
              { key: 'rank', name: 'Rang', width: 56, sortable: true },
              { key: 'category', name: 'Categorie', width: 78, sortable: true },
              { key: 'subRank', name: 'Cat. rang', width: 76, sortable: true },
              { key: 'name', name: 'Naam', sortable: true, filterable: true },
              { key: 'swim', name: 'Zwemtijd', width: 78, sortable: true },
              { key: 't1', name: 'T1', width: 78, sortable: true },
              { key: 'bike', name: 'Fietstijd', width: 78, sortable: true },
              { key: 't2', name: 'T2', width: 78, sortable: true },
              { key: 'run', name: 'Looptijd', width: 78, sortable: true },
              { key: 'total', name: 'Totaal', width: 78, sortable: true },
              { key: 'comment', name: 'Commentaar', width: 105, sortable: true }
            ]}
            rowGetter={(index) => this.rowGetter(index)}
            rowsCount={this.state.visiblePlayers.length}
            onGridSort={(sortColumn, sortDirection) => this.onGridSort(sortColumn, sortDirection)}
            onAddFilter={(filter) => this.onAddFilter(filter)}
          />
        </div>
      </div>
    );
  }
}

export default App